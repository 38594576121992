body {
  background-color: #000; margin: 0; color: #fff; 
  font-family: 'Roboto', Arial, sans-serif;
  font-display: optional;
  text-size-adjust: none;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
}
* { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; }

input {outline:none; border:0 }
