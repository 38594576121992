.active, .inactive { 
	display: inline-block;
  margin-right:10px;
  transition : all 0.3s ease-out;
  -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none;
}
.menuicon {
	height: 30px;
	width: 30px;
	position: relative;
	z-index: 2;
	cursor: pointer;
}
.line, .lineleft, .lineright {
	height: 2px;
	width: 30px;
	display: block;
	background-color: #fff;
	margin-bottom: 7px;
	cursor: pointer;
	-webkit-transition: background-color .5s ease, -webkit-transform .2s ease;
	transition: background-color .5s ease, -webkit-transform .2s ease;
	transition: transform .2s ease, background-color .5s ease;
	transition: transform .2s ease, background-color .5s ease, -webkit-transform .2s ease;
}
.lineleft {
	width: 16.5px;
	-webkit-transition: all 200ms linear;
	transition: all 200ms linear;
}
.lineright {
	width: 16.5px;
	float: right;
	-webkit-transition: all 200ms linear;
	-moz-transition: all 200ms linear;
	-o-transition: all 200ms linear;
	-ms-transition: all 200ms linear;
	transition: all 200ms linear;
}
.menuicon:hover .lineleft,
.menuicon:hover .lineright {
	width: 30px;
}

.active .line, .active .lineleft, .active .lineright  {
	background-color: #fff;
	-webkit-transform: translate(0px, 0px) rotate(-45deg);
          transform: translate(0px, 0px) rotate(-45deg);
}
.active .lineleft {
	width: 15px;
	-webkit-transform: translate(2px, 4px) rotate(45deg);
          transform: translate(2px, 4px) rotate(45deg);
}
.active .lineright {
	width: 15px;
	float: right;
	-webkit-transform: translate(-3px, -3.5px) rotate(45deg);
          transform: translate(-3px, -3.5px) rotate(45deg);
}
.active .menuicon:hover .lineleft,
.active .menuicon:hover .lineright {
	width: 15px;
}

.nav:before  {
	content: "";
	position: fixed;
	width: 100vw;
	height: 100vh;
	display:none;
	top:48px;
}
.content {
	position: fixed;
	visibility: hidden;
	top: 50%;
	left:0px;
	margin-top: 20px;
	-webkit-transform: translate(0%, -50%);
          transform: translate(0%, -50%);
	width: 100%;
	text-align: center;
}

.list {
	position: relative;
	padding: 0;
	margin: 0;
	z-index: 2;
}
.list li {
	position: relative;
	display: block;
	-webkit-transition-delay: 0.8s;
          transition-delay: 0.8s;
	opacity: 0;
	text-align: center;
	color: #fff;
	font-size: 6vh;
	font-weight: 900;
	line-height: 1.5;
	letter-spacing: 3px;
	-webkit-transform: translate(100px, 0%);
          transform: translate(100px, 0%);
	-webkit-transition: opacity .2s ease, -webkit-transform .3s ease;
	transition: opacity .2s ease, -webkit-transform .3s ease;
	transition: opacity .2s ease, transform .3s ease;
	transition: opacity .2s ease, transform .3s ease, -webkit-transform .3s ease;
}

.list a { 
	text-decoration: none;
	color: rgba(255,255,255,0.6);
	font-weight: 900;
	z-index: 2;
	display: inline-block;
}


.list a:hover {
	color: rgba(255,255,255,1);
}

.hide, .show { display:inline-block; }
.show .content { visibility: visible;  }

.show .nav:before {
	display:block; 
	background: rgba(0,0,0,0.9);
	 left:0px;
}

.show .list li {
	opacity: 1;
	-webkit-transform: translateX(0%);
          transform: translateX(0%);
}