.topbar {
  position: fixed;
  background-color: rgb(31,35,41);
  width: 100%;
  top: 0px;
  height: 48px;
  border-bottom: 1px solid black;
  z-index:101;
}
.wide { width:auto; margin:0 50px; }
.narrow { width:auto; margin:0 6px; }

.links { display:inline-block; vertical-align:top; font-size:18px; font-weight:bold;
  color:white; padding-top:10px; margin-left:36px; border-left:1px solid #444; 
  height: 38px; margin-top:4px;}

.links a { text-decoration:none; color:white; margin-left:36px; }
.links a:hover { color:#aaa;  }
.logowrap { display:inline-block; padding-top:6px; }