.search { float:right; font-size:18px; padding-top:4px;  }
.search a { text-decoration:none; }
.box, .boxmobile { padding-left:34px; font-size:18px; width:300px;font-family: Roboto, Arial, sans-serif; height:40px;
border-radius:20px; 
}
.boxmobile { width:150px; }
.label { position: relative; }

.label:before {
  content: ""; position: absolute; left: 10px; top: 0; bottom: 0; width: 20px;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25' height='25' viewBox='0 0 1000 1000' fill-rule='evenodd'%3E%3Cpath d='M 682 426 C 682 92.12 181 97 181 426 C 181 754 682 760 681.45 426 L 682 426 L 682 426 Z M 947 841 C 1022 911 910 1014 846 942 L 655 751 C 157 987 -144 420 173 143 C 541 -180 1012 314 756 650 L 947 841 L 947 841 Z'%3E%3C/path%3E%3C/svg%3E") center / contain no-repeat;
}

.results,.resultsmobile { 
  position:relative; font-size:13px; top:4px; background-color:white; 
  color:#111; border:1px solid #111; overflow:hidden; z-index:99999;
}
.resultsmobile { width:200px; margin-left:-50px; }

.row a { color: #111; }
.row a:hover { color:#111; }
.row > div:first-child { margin:2px 4px 2px 4px; border-radius:4px; }
.row > div:first-child a { border-radius:4px; }
.row > div:first-child a > div { padding:4px; line-height:32px; display:flex; }
.row > div:first-child a > div img { margin-right:4px; }
.row > div:first-child > a:nth-child(2) > div { padding-left:16px; }
.row > div:first-child > a:nth-child(2) img:last-child { margin-left:4px; }
.row > div.fullwidth:first-child{ display:block; }

.row a > div:hover { background-color:rgba(0,0,0,.3); }
.row a > div > div {display:inline-block; vertical-align:top; }

.profile { 
  width:32px; height:32px; border-radius:100%; 
  background-color:#555; border: 1px solid #888;  margin-right:12px;
}